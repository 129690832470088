.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
  }
  
  .link-button:hover,
  .link-button:focus {
  text-decoration: none;
  }

  .flags-right-aligned {
    padding-right: 120px !important;
}


.flag-select .flag-option {
  margin: 0;
}

.nav-wrapper {
  background-image: url("../src/components/resources/Derecha10.jpg");
  background-repeat: repeat-x;
  font-size: 14px;
  font-weight: bold;
  margin-top: -5px !important;
  margin-right: 0px;
}

.image-w-100 {
    width: 100%;
    display: block !important;
}

.image-flag {
  width: 25px !important;
}

@media only screen and (min-width: 1170px) {
.banner {
    height: 170px !important;
  }
}

@media only screen and (min-width: 500px) {
  .login {
        margin-top: 50px !important;
    }
  }
  
@media only screen and (min-width: 600px) {
  .login {
        margin-top: 150px !important;
    }
  }

.pb-5, .py-5 {
  padding-bottom: 0.3rem !important;
}

.pt-5, .py-5 {
  padding-top: 0.3rem !important;
}