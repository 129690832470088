body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
  }
  
  .link-button:hover,
  .link-button:focus {
  text-decoration: none;
  }

  .flags-right-aligned {
    padding-right: 120px !important;
}


.flag-select .flag-option {
  margin: 0;
}

.nav-wrapper {
  background-image: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/4QAiRXhpZgAATU0AKgAAAAgAAQESAAMAAAABAAEAAAAAAAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAClAAEDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwDwT7FH7UVs/wBjtRQedqcz/wAL0hor5l+0Sf3qK0DU5H/hPqK8t3N6UVmb+zG0V6z/AMKRm/540UE+1Nn/AIX3/wBNf1orwX7RRQbeyI6KkooKJKKKKACipKKALXk/5xRU1FADdlFOooAKKKKAP//Z);
  background-repeat: repeat-x;
  font-size: 14px;
  font-weight: bold;
  margin-top: -5px !important;
  margin-right: 0px;
}

.image-w-100 {
    width: 100%;
    display: block !important;
}

.image-flag {
  width: 25px !important;
}

@media only screen and (min-width: 1170px) {
.banner {
    height: 170px !important;
  }
}

@media only screen and (min-width: 500px) {
  .login {
        margin-top: 50px !important;
    }
  }
  
@media only screen and (min-width: 600px) {
  .login {
        margin-top: 150px !important;
    }
  }

.pb-5, .py-5 {
  padding-bottom: 0.3rem !important;
}

.pt-5, .py-5 {
  padding-top: 0.3rem !important;
}
